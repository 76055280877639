import React from 'react'
import './styles.scss'

export const BenefitBox = ({ title, text }) => {
  return (
    <div className="benefit-box">
      <h1 data-testid="benefit-box-title">{title}</h1>
      <p data-testid="benefit-box-text">{text}</p>
    </div>
  )
}

export default BenefitBox