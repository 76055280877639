import * as React from "react"
import Layout from "../components/Layout"
import "./pageStyles/home.scss"
import Seo from "../components/Seo"
import BenefitBox from "../components/BenefitBox"
import OrderingInformation from "../components/OrderingInformation"
import tableData from "../../data/ordering-information.json"

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <section className="protecting-every-breath-section">
        <div data-testid="protecting-banner" className="banner">
          <img decoding="async" src="https://res.cloudinary.com/dthskrjhy/image/upload/v1629122077/Arc_Medical/five-per-tube-long.png" alt="" title="five-per-tube-long"
            sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 800px, 100vw" />
          <h1>Protecting every breath.</h1>
        </div>
        <div className="banner-divider bottom" />
      </section>
      <section className="discover-benefits-section">
        <h2 data-testid="discover-header">
          Discover the benefits of CircuitGuard<sup>&reg;</sup>
        </h2>
        <p className="discover-text" data-testid="discover-text">
          Explore our collection of high-quality breathing circuits, filtration media, and more.
          Our anesthesia solutions are affordable, easy to use, and available in various
          configurations to seamlessly accommodate your process.
        </p>
        <div className="benefits-boxes-container">
          {
            [
              {
                title: "PATIENT COMFORT",
                text: "Heat and moisture exchanger (HME) provides humidity for the patient's airway."
              },
              {
                title: "OPTIMAL DESIGN",
                text: "Protects the patient, anesthesiologist, and circuit from potential risk of cross-contamination.\u00b9"
              },
              {
                title: "SPACE-CONSCIOUS",
                text: "Small footprint and consolidated inventory help reclaim valuable storage space."
              }
            ].map(({ title, text }, ind) => (
              <BenefitBox key={ind} title={title} text={text} />
            ))
          }
        </div>
      </section>
      <section className="ordering-information-section">
        <div className="banner-divider top" />
        <div className="banner">
          <h1>Ordering Information</h1>
          <OrderingInformation {...tableData} />
        </div>
        <div className="banner-divider bottom" />
      </section>
      <section className="connect-section text-center">
        <h1>Connect with a Typenex<sup>&reg;</sup> Medical representative to learn more about CircuitGuard<sup>&reg;</sup> and our other anesthesia filtration solutions.</h1>
      </section>
      <section className="footnotes">
        <p>
          <b>Note:</b> The use of this product is not intended to modify the breathing circuit manufacturer's
          recommended instructions for use, e.g. disposal or cleaning and disinfection after use.
        </p>
        <p>
          <b>Caution:</b> Federal law restricts this device to sale by or on the order of a physician.
        </p>
        <p>
          <sup>1</sup>Wilkes, A.R., Benbough, J.E., Speight, S.E. and Harmer, M. (2000), "The bacterial and viral filtration performance of breathing
          system filters*"" Anaesthesia, 55: 458-465. https://doi.org/10.1046/j.1365-2044.2000.01327.x
        </p>
      </section>
    </Layout >
  )
}

export default IndexPage