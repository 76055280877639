import React from 'react'
import './styles.scss'

export const OrderingInformation = ({ headers, rows, align }) => {
  return (
    <div className="ordering-information w-100">
      <table>
        <thead>
          <tr>
            {
              headers.map(({ name }, headerInd) => {
                return <th key={headerInd} className={`text-${align || "center"}`}>{name}</th>
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, rowInd) => {
              return (
                <tr key={rowInd}>
                  {row.cellData.map((cell, cellInd) => {
                    return (
                      <td key={cellInd} className={`text-${align || 'center'}`}>
                        {
                          cell.split('\n').map((line, ind) => {
                            return (
                              <span key={ind}>
                                {ind > 0 && <br />}
                                {line}
                              </span>
                            )
                          })
                        }
                      </td>
                    )
                  })}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default OrderingInformation